<template>
  <div class="flex min-h-screen items-center justify-center bg-black p-4">
    <div class="w-full max-w-md space-y-8 text-center">
      <div class="space-y-4">
        <UIcon name="i-lucide-alert-circle" class="mx-auto h-24 w-24 text-red-500" />
        <h1 class="text-4xl font-bold text-white">Oops! An error occurred</h1>
        <p class="text-xl text-gray-400">{{ errorMessage }}</p>
        <p class="text-7xl font-bold text-red-500">{{ errorCode }}</p>
      </div>
      <div class="mt-8 space-y-4">
        <p class="text-gray-500">
          Don't worry, our team has been notified and we're working on it.
        </p>
        <button
          class="inline-flex items-center rounded-md border border-transparent bg-white px-6 py-3 text-base font-medium text-black transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
          @click="goHome"
        >
          <UIcon name="i-lucide-home" class="mr-2 h-5 w-5" />
          Return to Home
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted } from 'vue';

  const props = defineProps({
    errorCode: {
      type: String,
      default: '404',
    },
    errorMessage: {
      type: String,
      default: 'Page not found',
    },
  });

  const goHome = () => {
    // Replace this with your actual routing logic
    console.log('Navigating to home page');
    // If you're using Vue Router, you would use something like:
    navigateTo('/');
  };

  onMounted(() => {
    document.title = `Error ${props.errorCode} | Your App Name`;
  });
</script>

<style scoped>
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .max-w-md {
    animation: fadeIn 0.5s ease-out;
  }
</style>
