export const useSharedStore = defineStore('useSharedStore', () => {
  const currentReservationID = ref<number>(null);
  const getUrlReservation = computed<string>(
    () => `/api/v1/reservations/${currentReservationID.value}`
  );
  const setCurrentReservationId = async (id: number) => {
    currentReservationID.value = id;
  };

  return {
    currentReservationID,
    getUrlReservation,
    setCurrentReservationId,
  };
});
